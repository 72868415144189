import React, { ReactElement } from 'react';

import { MdDashboard, MdAttachMoney } from 'react-icons/md';
import { GoSettings, GoNote } from 'react-icons/go';
import { TiScissors } from 'react-icons/ti';
import { FiTruck } from 'react-icons/fi';
import { useSiedeBar } from 'contexts';
import * as S from './styles';
import { ItemMenu } from './ItemMenu';

interface Menu {
  name: string;
  route: string;
  icon?: ReactElement | null;
  children: Menu[];
  perm?: string;
}

export const SideBar: React.FC = () => {
  const { isOpened } = useSiedeBar();

  const menus: Menu[] = [
    {
      name: 'Dashboard',
      route: '/dashboard',
      icon: <MdDashboard size={24} color="#707070" />,
      children: [],
    },
    // {
    //   name: 'Analytics',
    //   route: '#',
    //   icon: <MdDashboard size={24} color="#707070" />,
    //   children: [
    //     {
    //       name: 'Estoque',
    //       route: '/analytics/estoque',
    //       icon: null,
    //       perm: 'ajuste_estoque_list',
    //       children: [],
    //     },
    //     {
    //       name: 'Financeiro',
    //       route: '/analytics/financeiro',
    //       icon: null,
    //       perm: 'contas_receber_list',
    //       children: [],
    //     },
    //     {
    //       name: 'Vendas',
    //       route: '/analytics/vendas',
    //       icon: null,
    //       perm: 'vendas_list',
    //       children: [],
    //     },
    //     {
    //       name: 'RaioX',
    //       route: '/analytics/raiox',
    //       icon: null,
    //       perm: 'vendas_list',
    //       children: [],
    //     },
    //   ],
    // },
    {
      name: 'Cadastros',
      route: '#',
      icon: <GoSettings size={24} color="#707070" />,
      children: [
        {
          name: 'Centro Custo',
          route: '/centro-custo',
          icon: null,
          perm: 'centro_custo_list',
          children: [],
        },
        {
          name: 'Clientes',
          route: '/clientes',
          icon: null,
          perm: 'clientes_list',
          children: [],
        },
        {
          name: 'Contas',
          route: '/contas',
          icon: null,
          perm: 'contas_list',
          children: [],
        },
        {
          name: 'Cores',
          route: '/produto/cores',
          icon: null,
          perm: 'produtos_list',
          children: [],
        },
        {
          name: 'Familias',
          route: '/produto/familias',
          icon: null,
          perm: 'produtos_list',
          children: [],
        },
        {
          name: 'Formas Pagamento',
          route: '/formas-pagamento',
          icon: null,
          perm: 'formas_pagamento_list',
          children: [],
        },
        {
          name: 'Fornecedores',
          route: '/fornecedores',
          icon: null,
          perm: 'fornecedores_list',
          children: [],
        },

        {
          name: 'Permissões',
          route: '/permissions',
          icon: null,
          perm: 'permissions_list',
          children: [],
        },
        {
          name: 'Plano Contas Pai',
          route: '/plano-contas-pai',
          icon: null,
          perm: 'plano_contas_list',
          children: [],
        },
        {
          name: 'Plano Contas',
          route: '/plano-contas',
          icon: null,
          perm: 'plano_contas_list',
          children: [],
        },
        {
          name: 'Produtos',
          route: '/produtos',
          icon: null,
          perm: 'produtos_list',
          children: [],
        },
        {
          name: 'Usuários',
          route: '/users',
          icon: null,
          perm: 'users_list',
          children: [],
        },
      ],
    },

    // {
    //   name: 'Produção',
    //   route: '#',
    //   icon: <TiScissors size={24} color="#707070" />,
    //   children: [
    //     {
    //       name: 'Envio corte',
    //       route: '/envio-corte',
    //       icon: null,
    //       perm: 'envio_corte_list',
    //       children: [],
    //     },
    //     {
    //       name: 'Recebimento corte',
    //       route: '/recebimento-corte',
    //       icon: null,
    //       perm: 'recebimento_corte_list',
    //       children: [],
    //     },
    //   ],
    // },
    // {
    //   name: 'Vendas',
    //   route: '#',
    //   icon: <GoNote size={24} color="#707070" />,
    //   children: [
    //     {
    //       name: 'Vendas',
    //       route: '/vendas',
    //       icon: null,
    //       perm: 'vendas_list',
    //       children: [],
    //     },
    //     {
    //       name: 'Vendas Mobile',
    //       route: '/mobile/vendas',
    //       icon: null,
    //       perm: 'vendas_list',
    //       children: [],
    //     },
    //   ],
    // },
    // {
    //   name: 'Estoque',
    //   route: '#',
    //   icon: <FiTruck size={20} color="#707070" />,
    //   children: [
    //     {
    //       name: 'Ajuste Estoque',
    //       route: '/ajuste-estoque',
    //       icon: null,
    //       perm: 'ajuste_estoque_list',
    //       children: [],
    //     },
    //   ],
    // },
    {
      name: 'Compras Fornecedor',
      route: '#',
      icon: <MdAttachMoney size={24} color="#707070" />,
      children: [
        {
          name: 'Títulos',
          route: '/contas-pagar',
          icon: null,
          perm: 'contas_pagar_list',
          children: [],
        },
        {
          name: 'Pagamentos',
          route: '/baixas/contas-pagar',
          icon: null,
          perm: 'contas_pagar_baixas_list',
          children: [],
        },
        {
          name: 'Consolidado',
          route: '/relatorios/contas-pagar/consolidado',
          icon: null,
          perm: 'contas_pagar_list',
          children: [],
        },
      ],
    },
    {
      name: 'Vendas Cliente',
      route: '#',
      icon: <MdAttachMoney size={24} color="#707070" />,
      children: [
        {
          name: 'Títulos',
          route: '/contas-receber',
          icon: null,
          perm: 'contas_receber_list',
          children: [],
        },
        {
          name: 'Recebimentos',
          route: '/baixas/contas-receber',
          icon: null,
          perm: 'contas_receber_baixas_list',
          children: [],
        },
        {
          name: 'Consolidado',
          route: '/relatorios/contas-receber/consolidado',
          icon: null,
          perm: 'contas_receber_list',
          children: [],
        },
      ],
    },
    // {
    //   name: 'Costureiras',
    //   route: '#',
    //   icon: <MdAttachMoney size={24} color="#707070" />,
    //   children: [
    //     {
    //       name: 'Títulos',
    //       route: '/contas-pagar-costureira',
    //       icon: null,
    //       perm: 'contas_pagar_costureira_list',
    //       children: [],
    //     },
    //     {
    //       name: 'Pagamentos',
    //       route: '/baixas/contas-pagar-costureira',
    //       icon: null,
    //       perm: 'contas_pagar_costureira_baixas_list',
    //       children: [],
    //     },
    //     {
    //       name: 'Consolidado',
    //       route: '/relatorios/contas-pagar-costureira/consolidado',
    //       icon: null,
    //       perm: 'contas_pagar_costureira_list',
    //       children: [],
    //     },
    //   ],
    // },
    {
      name: 'Pessoal',
      route: '#',
      icon: <MdAttachMoney size={24} color="#707070" />,
      children: [
        {
          name: 'Títulos',
          route: '/contas-pagar-pessoal',
          icon: null,
          perm: 'contas_pagar_pessoal_list',
          children: [],
        },
        {
          name: 'Pagamentos',
          route: '/baixas/contas-pagar-pessoal',
          icon: null,
          perm: 'contas_pagar_pessoal_baixas_list',
          children: [],
        },
        {
          name: 'Consolidado',
          route: '/relatorios/contas-pagar-pessoal/consolidado',
          icon: null,
          perm: 'contas_pagar_pessoal_list',
          children: [],
        },
      ],
    },
  ];

  return (
    <S.Container isMenuOpened={isOpened}>
      <S.MainMenu>
        {menus.map((menu: Menu) => (
          <ItemMenu key={menu.name} menu={menu} />
        ))}
      </S.MainMenu>
    </S.Container>
  );
};

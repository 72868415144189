import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaMoneyBill } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import {
  createTitulo,
  getTitulo,
  updateTitulo,
} from 'services/api/contasReceber';

import { getClientes } from 'services/api/cliente';
import { getFormasPagamento } from 'services/api/formaPagamento';

import { useToast } from 'contexts';

import * as C from 'components';
import * as S from './styles';

import { IContasReceber, IContasReceberForm } from '../interfaces';

const schema = Yup.object().shape({
  cliente_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Cliente obrigatorio')
    .typeError('Cliente obrigatorio'),
  forma_pagamento_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Forma pagamento obrigatorio')
    .typeError('Forma pagamento obrigatorio'),
  valor_titulo: Yup.string()
    .typeError('Valor da venda obrigatório')
    .required('Valor da venda obrigatório'),
  data_vencimento: Yup.string()
    .typeError('Data vencimento obrigatório')
    .required('Data vencimento obrigatório'),
  data_venda: Yup.string()
    .typeError('Data venda obrigatório')
    .required('Data venda obrigatório'),
});

export const ContasReceberForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setError,
  } = useForm<FieldValues>({
    defaultValues: {
      valor_titulo: '0,00',
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState<IContasReceber>();

  const loadOptionsClientes = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getClientes({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsFormas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFormasPagamento({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  useEffect(() => {
    if (id) {
      getTitulo(id).then((data) => {
        setTitulo(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: IContasReceberForm) => {
      try {
        setLoading(true);

        if (id) {
          if (!data.cliente_id?.value) {
            setError('cliente_id', { message: 'Cliente obrigatorio' });

            return;
          }

          if (!data.forma_pagamento_id?.value) {
            setError('forma_pagamento_id', {
              message: 'Forma pagamento obrigatorio',
            });

            return;
          }

          if (data.valor_titulo === '0,00') {
            setError('valor_titulo', {
              message: 'Valor da venda obrigatório',
            });

            return;
          }

          const response = await updateTitulo(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/contas-receber');
          }
        } else {
          if (!data.cliente_id?.value) {
            setError('cliente_id', { message: 'Cliente obrigatorio' });

            return;
          }

          if (!data.forma_pagamento_id?.value) {
            setError('forma_pagamento_id', {
              message: 'Forma pagamento obrigatorio',
            });

            return;
          }

          if (data.valor_titulo === '0,00') {
            setError('valor_titulo', {
              message: 'Valor da venda obrigatório',
            });

            return;
          }

          await createTitulo(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/contas-receber');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset, setError],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/contas-receber">
              <MdKeyboardBackspace size={24} color="#fff" />
              Titulos Receber
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id
                ? `Editando título cliente: ${titulo?.cliente.nome}`
                : 'Novo titulo'
            }
            icon={FaMoneyBill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Data Feira</C.Label>
              <C.InputDatePicker
                {...register('data_venda')}
                control={control}
                errors={errors.data_venda}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_venda')}
                name="data_venda"
                placeholder="Data venda"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data Vencimento</C.Label>
              <C.InputDatePicker
                {...register('data_vencimento')}
                control={control}
                errors={errors.data_vencimento}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_vencimento')}
                name="data_vencimento"
                placeholder="Data Vencimento"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Cliente</C.Label>
              <C.ReactSelectAsync
                {...register('cliente_id')}
                control={control}
                errors={errors.cliente_id}
                loadOptions={loadOptionsClientes}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum cliente localizado"
              />
            </C.FormGroup>

            {/* <C.FormGroup>
              <C.Label>Data Feira</C.Label>
              <C.InputDatePicker
                {...register('data_feira')}
                control={control}
                errors={errors.data_feira}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_feira')}
                name="data_feira"
                placeholder="Data Feira"
              />
            </C.FormGroup> */}

            <C.FormGroup>
              <C.Label>Forma</C.Label>
              <C.ReactSelectAsync
                {...register('forma_pagamento_id')}
                control={control}
                errors={errors.forma_pagamento_id}
                loadOptions={loadOptionsFormas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma forma pagamento localizada"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor Título</C.Label>
              <C.InputCurrency
                {...register('valor_titulo')}
                errors={errors.valor_titulo}
                name="valor_titulo"
                placeholder="Ex: 12,50"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Observação</C.Label>
              <C.Input
                type="text"
                {...register('observacao')}
                errors={errors.observacao}
                name="observacao"
                placeholder="Observação"
              />
            </C.FormGroup>
          </S.FormContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};

import api from 'services/api';

import { IPaginate, IParams } from 'shared/interfaces';
import {
  IContasReceber,
  IContasReceberForm,
  IRelatorioContasReceberForm,
} from 'pages/ContasReceber/interfaces';

import { IRelatorioContasReceberBaixasForm } from 'pages/ContasReceberBaixas/interfaces';

import { formatCurrencyBR, formatCurrencyDataBase } from 'utils/helpers';
import { CustomFormats, toFormatDate } from '../../../utils/dateHelpers';

const createTituloData = (titulo: IContasReceberForm) => {
  const data = {
    ...titulo,
    cliente_id: titulo.cliente_id.value,
    forma_pagamento_id: titulo.forma_pagamento_id.value,
    valor_titulo: formatCurrencyDataBase(titulo.valor_titulo),
  };

  return data;
};

const getTitulo = async (id: string): Promise<IContasReceber> => {
  const { data } = await api.get<IContasReceber>(`/contas-receber/${id}`);

  const tituloFormatadado = {
    ...data,
    valor_titulo: formatCurrencyBR(Number(data.valor_titulo), false),
    data_venda: data.data_venda ? new Date(data.data_venda) : null,
    data_vencimento: data.data_vencimento
      ? new Date(data.data_vencimento)
      : null,
    // data_feira: data.data_feira ? new Date(data.data_feira) : null,
    cliente_id: data?.cliente?.id
      ? {
          label: String(data?.cliente?.nome) || '',
          value: String(data?.cliente?.id) || '',
        }
      : '',
    forma_pagamento_id: data?.forma_pagamento?.id
      ? {
          label: String(data?.forma_pagamento?.nome) || '',
          value: String(data?.forma_pagamento?.id) || '',
        }
      : '',
  };

  return tituloFormatadado;
};

const getTitulos = async (
  params: IParams,
): Promise<IPaginate<IContasReceber>> => {
  const response = await api.get<IPaginate<IContasReceber>>('/contas-receber', {
    params,
  });

  const titulosFormatado = response.data.data.map((item: IContasReceber) => {
    return {
      ...item,
      valor_titulo: formatCurrencyBR(Number(item.valor_titulo)),
      data_venda: item.data_venda
        ? toFormatDate(new Date(item.data_venda), CustomFormats.dd_MM_yyyy)
        : null,
      data_vencimento: item.data_vencimento
        ? toFormatDate(new Date(item.data_vencimento), CustomFormats.dd_MM_yyyy)
        : null,
      // data_feira: item.data_feira
      //   ? toFormatDate(new Date(item.data_feira), CustomFormats.dd_MM_yyyy)
      //   : null,
    };
  });

  return {
    ...response.data,
    data: titulosFormatado,
  };
};

const createTitulo = async (titulo: IContasReceberForm): Promise<void> => {
  const data = createTituloData(titulo);

  await api.post('/contas-receber', data);
};

const updateTitulo = async (
  id: string,
  titulo: IContasReceberForm,
): Promise<IContasReceber> => {
  const data = createTituloData(titulo);

  const response = await api.put<IContasReceber>(`/contas-receber/${id}`, data);

  return response.data;
};

const deleteTitulo = async (titulo: {
  id: string;
  motivo_cancelamento?: string;
}): Promise<void> => {
  await api.delete(`/contas-receber/${titulo.id}`, {
    data: { motivo_cancelamento: titulo.motivo_cancelamento },
  });
};

const baixarTitulo = async (id: string): Promise<void> => {
  await api.get(`/contas-receber/status/${id}`);
};

const getRelatorioTitulo = async (
  params: IRelatorioContasReceberForm,
): Promise<{ link: string }> => {
  const data = {
    ...params,
    cliente_id: params.cliente_id ? params.cliente_id.value : '',
    forma_pagamento_id: params.forma_pagamento_id
      ? params.forma_pagamento_id.value
      : '',
    data_venda_inicial: params.data_venda_inicial
      ? new Date(params.data_venda_inicial)
      : '',
    data_venda_final: params.data_venda_final
      ? new Date(params.data_venda_final)
      : '',
    // data_feira_inicial: params.data_feira_inicial
    //   ? new Date(params.data_feira_inicial)
    //   : '',
    // data_feira_final: params.data_feira_final
    //   ? new Date(params.data_feira_final)
    //   : '',
    data_vencimento_inicial: params.data_vencimento_inicial
      ? new Date(params.data_vencimento_inicial)
      : '',
    data_vencimento_final: params.data_vencimento_final
      ? new Date(params.data_vencimento_final)
      : '',
    status: params.status ? params.status.value : 'ALL',
  };

  const response = await api.post(`/relatorios/contas-receber/titulos`, data);

  return response.data;
};

const getRelatorioBaixas = async (
  params: IRelatorioContasReceberBaixasForm,
): Promise<{ link: string }> => {
  const data = {
    ...params,
    cliente_id: params.cliente_id ? params.cliente_id.value : '',
    forma_pagamento_id: params.forma_pagamento_id
      ? params.forma_pagamento_id.value
      : '',
    conta_id: params.conta_id ? params.conta_id.value : '',
    data_pagamento_inicial: params.data_pagamento_inicial
      ? new Date(params.data_pagamento_inicial)
      : '',
    data_pagamento_final: params.data_pagamento_final
      ? new Date(params.data_pagamento_final)
      : '',
    data_feira_inicial: params.data_feira_inicial
      ? new Date(params.data_feira_inicial)
      : null,
    data_feira_final: params.data_feira_final
      ? new Date(params.data_feira_final)
      : null,
  };

  const response = await api.post(`/relatorios/contas-receber/baixas`, data);

  return response.data;
};

export {
  getTitulo,
  getTitulos,
  createTitulo,
  updateTitulo,
  deleteTitulo,
  baixarTitulo,
  getRelatorioTitulo,
  getRelatorioBaixas,
};

import { IPaginate, IParams } from 'shared/interfaces';
import { ICliente, IClienteForm } from 'pages/Cliente/interfaces';

import { maskCep, maskCpf, maskCnpj } from 'utils/masks';
import api from '../../api';

const getCliente = async (id: string): Promise<ICliente> => {
  const response = await api.get<ICliente>(`/clientes/${id}`);

  const clienteFormatado = {
    ...response.data,
    // tipo_pessoa: response.data.tipo_pessoa
    //   ? {
    //       label:
    //         String(
    //           response.data?.tipo_pessoa === 'F' ? 'Física' : 'Jurídica',
    //         ) || '',
    //       value: String(response.data?.tipo_pessoa) || '',
    //     }
    //   : '',
    // data_nascimento: response.data.data_nascimento
    //   ? new Date(response.data.data_nascimento)
    //   : null,
    // cep: maskCep('', response.data.cep),
    // cnpj:
    //   response.data.tipo_pessoa === 'F'
    //     ? maskCpf('', response.data.cnpj)
    //     : maskCnpj('', response.data.cnpj),
  };

  return clienteFormatado;
};

const getClientes = async (params: IParams): Promise<IPaginate<ICliente>> => {
  const response = await api.get<IPaginate<ICliente>>('/clientes', { params });

  return response.data;
};

const createCliente = async (cliente: IClienteForm): Promise<void> => {
  const data = {
    ...cliente,
    // tipo_pessoa: cliente.tipo_pessoa.value,
    // cep: cliente.cep.replace(/\D/g, ''),
    // cnpj: cliente.cnpj.replace(/\D/g, ''),
  };

  await api.post('/clientes', data);
};

const updateCliente = async (
  id: string,
  cliente: IClienteForm,
): Promise<ICliente> => {
  const data = {
    nome: cliente.nome,
    // tipo_pessoa: cliente.tipo_pessoa.value,
    // cep: cliente.cep.replace(/\D/g, ''),
    // cnpj: cliente.cnpj.replace(/\D/g, ''),
  };

  const response = await api.put<ICliente>(`/clientes/${id}`, data);

  return response.data;
};

const deleteCliente = async (id: string): Promise<void> => {
  await api.delete(`/clientes/${id}`);
};

const enableCliente = async (id: string): Promise<ICliente> => {
  const response = await api.get(`/clientes/enable/${id}`);

  return response.data;
};

export {
  getCliente,
  getClientes,
  createCliente,
  updateCliente,
  deleteCliente,
  enableCliente,
};

import React, { useCallback, useState, useEffect } from 'react';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useForm, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { FaMoneyBill } from 'react-icons/fa';
import { MdKeyboardBackspace } from 'react-icons/md';
import { OptionTypeBase } from 'react-select';

import { onDelay } from 'utils/debounce';

import { IRouterParams, IOptionReactSelect } from 'shared/interfaces';

import {
  createTitulo,
  getTitulo,
  updateTitulo,
} from 'services/api/contasPagar';

import { getFornecedores, getFornecedor } from 'services/api/fornecedor';
import { getFormasPagamento } from 'services/api/formaPagamento';
import { getCentroCustos } from 'pages/CentroCusto/api';
import { getPlanoContas } from 'pages/PlanoContas/api';

import { useToast } from 'contexts';
import { formatCurrencyDataBase, formatCurrencyBR } from 'utils/helpers';

import * as C from 'components';
import * as S from './styles';

import { IContasPagar, IContasPagarForm } from '../interfaces';

const schema = Yup.object().shape({
  fornecedor_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Fornecedor obrigatorio')
    .typeError('Fornecedor obrigatorio'),
  forma_pagamento_id: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.string(),
    })
    .required('Forma pagamento obrigatorio')
    .typeError('Forma pagamento obrigatorio'),
  valor_compra: Yup.string()
    .typeError('Valor da compra obrigatório')
    .required('Valor da compra obrigatório'),
  data_vencimento: Yup.string()
    .typeError('Data vencimento obrigatório')
    .required('Data vencimento obrigatório'),
  data_compra: Yup.string()
    .typeError('Data compra obrigatório')
    .required('Data compra obrigatório'),
});

export const ContasPagarForm: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();

  const { id } = useParams<IRouterParams>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
    setValue,
    setError,
  } = useForm<FieldValues>({
    defaultValues: {
      valor_compra: '0,00',
      fornecedor_id: '',
      forma_pagamento_id: '',
    },
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [titulo, setTitulo] = useState<IContasPagar>();

  const loadOptionsFormas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFormasPagamento({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsCentroCusto = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getCentroCustos({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsPlanoContas = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getPlanoContas({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  const loadOptionsFornecedores = onDelay(
    (
      inputValue: string,
      callback: (options: OptionTypeBase[]) => void,
    ): void => {
      getFornecedores({
        page: 1,
        per_page: 15,
        term: inputValue,
      }).then((response) => {
        const values: IOptionReactSelect[] = response.data.map((item) => {
          return {
            label: item.nome,
            value: item.id,
          };
        });

        return callback(values);
      });
    },
    500,
  );

  // const valorNota = watch('valor_nota_fiscal');
  // const valorRomaneio = watch('valor_romaneio');
  // const isSplit = watch('split');
  const fornecedorSelected = watch('fornecedor_id');
  // const centroCustoSelected = watch('centro_custo_id');
  // const planoContasSelected = watch('plano_contas_id');
  // const formaPagamentoSelected = watch('forma_pagamento_id');

  // useEffect(() => {
  //   const total = isSplit
  //     ? formatCurrencyDataBase(valorNota) +
  //       formatCurrencyDataBase(valorRomaneio)
  //     : formatCurrencyDataBase(valorRomaneio);

  //   setValue('valor_compra', formatCurrencyBR(total, false));
  // }, [isSplit, setValue, valorNota, valorRomaneio]);

  // useEffect(() => {
  //   if (fornecedorSelected) {
  //     const getCustos = async () => {
  //       const forn = await getFornecedor(fornecedorSelected.value);

  //       if (forn && !centroCustoSelected) {
  //         setValue('centro_custo_id', forn.centro_custo_id);
  //       }

  //       if (forn && !planoContasSelected) {
  //         setValue('plano_contas_id', forn.plano_contas_id);
  //       }

  //       if (forn && !formaPagamentoSelected) {
  //         setValue('forma_pagamento_id', forn.forma_pagamento_id);
  //       }
  //     };

  //     getCustos();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [fornecedorSelected]);

  useEffect(() => {
    if (id) {
      getTitulo(id).then((data) => {
        setTitulo(data);

        reset(data);
      });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data: IContasPagarForm) => {
      try {
        setLoading(true);

        if (id) {
          if (!data.fornecedor_id?.value) {
            setError('fornecedor_id', { message: 'Fornecedor obrigatorio' });

            return;
          }

          if (!data.forma_pagamento_id?.value) {
            setError('forma_pagamento_id', {
              message: 'Forma pagamento obrigatorio',
            });

            return;
          }

          if (data.valor_compra === '0,00') {
            setError('valor_compra', {
              message: 'Valor da compra obrigatório',
            });

            return;
          }

          const response = await updateTitulo(id, data);

          if (response) {
            addToast({
              type: 'success',
              title: 'Atualizado',
              description: 'Atualizado com sucesso',
            });

            history.push('/contas-pagar');
          }
        } else {
          if (!data.fornecedor_id?.value) {
            setError('fornecedor_id', { message: 'Fornecedor obrigatorio' });

            return;
          }

          if (!data.forma_pagamento_id?.value) {
            setError('forma_pagamento_id', {
              message: 'Forma pagamento obrigatorio',
            });

            return;
          }

          if (data.valor_compra === '0,00') {
            setError('valor_compra', {
              message: 'Valor da compra obrigatório',
            });

            return;
          }

          await createTitulo(data);

          addToast({
            type: 'success',
            title: 'Sucesso',
            description: 'Salvo com sucesso',
          });

          reset();

          history.push('/contas-pagar');
        }
      } catch (err) {
        addToast({
          type: 'error',
          title: 'Ops, Erro',
          description: 'Ocorreu um erro ao inserir um novo registro',
        });
      } finally {
        setLoading(false);
      }
    },

    [addToast, history, id, reset, setError],
  );

  return (
    <S.Container>
      <C.HeaderBackground>
        <S.HeaderPageDatail>
          <S.BackLink>
            <Link to="/contas-pagar">
              <MdKeyboardBackspace size={24} color="#fff" />
              Titulos Pagar
            </Link>
          </S.BackLink>

          <C.HeaderInfo
            title={
              id
                ? `Editando título fornecedor: ${titulo?.fornecedor.nome}`
                : 'Novo titulo'
            }
            icon={FaMoneyBill}
          />
        </S.HeaderPageDatail>
      </C.HeaderBackground>

      <S.Panel>
        <form onSubmit={handleSubmit(onSubmit)}>
          <S.FormContainer>
            <C.FormGroup>
              <C.Label>Fornecedor</C.Label>
              <C.ReactSelectAsync
                {...register('fornecedor_id')}
                control={control}
                errors={errors.fornecedor_id}
                loadOptions={loadOptionsFornecedores}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum fornecedor localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Forma</C.Label>
              <C.ReactSelectAsync
                {...register('forma_pagamento_id')}
                control={control}
                errors={errors.forma_pagamento_id}
                loadOptions={loadOptionsFormas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma forma pagamento localizada"
              />
            </C.FormGroup>

            {/* <C.FormGroup>
              <C.Label>Centro Custo</C.Label>
              <C.ReactSelectAsync
                {...register('centro_custo_id')}
                control={control}
                errors={errors.centro_custo_id}
                loadOptions={loadOptionsCentroCusto}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhum centro custo localizado"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Plano Contas</C.Label>
              <C.ReactSelectAsync
                {...register('plano_contas_id')}
                control={control}
                errors={errors.plano_contas_id}
                loadOptions={loadOptionsPlanoContas}
                isClearable
                isSearchable
                loadingMessage="Buscando..."
                noOptionsMessage="Nenhuma plano de contas localizado"
              />
            </C.FormGroup> */}

            <C.FormGroup>
              <C.Label>Data Compra</C.Label>
              <C.InputDatePicker
                {...register('data_compra')}
                control={control}
                errors={errors.data_compra}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_compra')}
                name="data_compra"
                placeholder="Data Compra"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Data Vencimento</C.Label>
              <C.InputDatePicker
                {...register('data_vencimento')}
                control={control}
                errors={errors.data_vencimento}
                dateFormat="dd/MM/yyyy"
                selected={watch('data_vencimento')}
                name="data_vencimento"
                placeholder="Data Vencimento"
              />
            </C.FormGroup>

            {/* <C.FormGroup>
              <C.Label>Nota Fiscal</C.Label>
              <C.Input
                type="text"
                {...register('nota_fiscal')}
                errors={errors.nota_fiscal}
                name="nota_fiscal"
                placeholder="Número nota fiscal"
              />
            </C.FormGroup> */}

            <C.FormGroup>
              <C.Label>Romaneio</C.Label>
              <C.Input
                type="text"
                {...register('numero_romaneio')}
                errors={errors.numero_romaneio}
                name="numero_romaneio"
                placeholder="Número romaneiro"
              />
            </C.FormGroup>

            <C.FormGroup>
              <C.Label>Valor compra</C.Label>
              <C.InputCurrency
                {...register('valor_compra')}
                errors={errors.valor_compra}
                name="valor_compra"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup>

            {/* <C.FormGroup>
              <C.Label>Valor nota fiscal</C.Label>
              <C.InputCurrency
                {...register('valor_nota_fiscal')}
                errors={errors.valor_nota_fiscal}
                name="valor_nota_fiscal"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup> */}

            {/* <C.FormGroup>
              <C.Label>Valor Romaneio</C.Label>
              <C.InputCurrency
                {...register('valor_romaneio')}
                errors={errors.valor_romaneio}
                name="valor_romaneio"
                placeholder="Ex: 19,90"
              />
            </C.FormGroup> */}

            {/* <C.FormGroup>
              <C.Checkbox
                {...register('split')}
                name="split"
                items={[{ id: 1, name: 'Pagamento dividido?' }]}
              />
            </C.FormGroup> */}
          </S.FormContainer>

          <C.Button
            variant="primary"
            disabled={loading}
            loading={loading}
            type="submit"
            title="Salvar"
          >
            Salvar
          </C.Button>
        </form>
      </S.Panel>
    </S.Container>
  );
};
